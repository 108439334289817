const cleanLockersReducer = (state = { lockersToClean : null }, action) => {
  switch (action.type) {
    case "GET_CLEAN_LOCKERS_SUCCESS":
      return { ...state, lockersToClean: action.payload }
    default:
      return state
  }
}

export default cleanLockersReducer
