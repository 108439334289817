import React from "react"
import "assets/scss/components/locker.scss"
import { ReactSVG } from 'react-svg'
import moment from 'moment'
import 'moment/locale/pt'
import { getTimeAgoClass } from 'utility/time/get-time-ago-class'
import { withTranslation } from 'react-i18next'
import { connect } from "react-redux"
import { calculateRemainingTime } from "utility/marketplace"

class Locker extends React.Component {
  getLockerClass = (locker) => {
    if ((this.props.isOwnedByCurrentUser || this.props.isOwnedByCurrentAsset) && locker.doorStatus === 0) return 'bg-doorOpen'
    if ((this.props.isOwnedByCurrentUser || this.props.isOwnedByCurrentAsset) && locker.status === 1) return 'bg-reserved'
    if ((this.props.isOwnedByCurrentUser || this.props.isOwnedByCurrentAsset) && locker.status === 2) return 'bg-occupied'
    if (locker.enabled === 0) {
      return 'bg-doorDisabled'
    } else if (locker.enabled === 1) {
      /*if(moment(new Date()).diff(moment(locker.statusChangeAt), 'seconds') > process.env.REACT_APP_LOCKER_EXPIRES_AFTER_SECONDS) return 'expired'*/
      if (locker.doorStatus === 0) return 'bg-doorOpen'
      if (locker.doorStatus === 1) return 'bg-alert'
      if (locker.doorStatus === 3) return 'bg-alert'
      if (locker.doorStatus === 11) return 'bg-alert'
      if (locker.doorStatus === 255) return 'bg-offline'
      if (locker.accessibility && locker.status === 0) return 'bg-info'
      if (locker.status === 0) return 'bg-available'
      if (locker.status === 1) return 'bg-reserved'
      if (locker.status === 2) return 'bg-occupied'
      if (locker.status === 3) return 'bg-reserved'
      if (locker.status === 4) return 'bg-occupied'
      if (locker.status === 5) return 'bg-reserved'
      if (locker.status === 6) return 'bg-occupied'
      if (locker.status === 7) return 'bg-pending'
      if (locker.status === 8) return 'bg-safeTime'
    } else if (locker.enabled === 3) {
      return 'bg-clean'
    }
  }

  getLockerIcon = (locker) => {
    if (locker.enabled === 0 || locker.enabled === 1) {
      if (this.props.isOwnedByCurrentUser || this.props.isOwnedByCurrentAsset) return '/lockers/user_own_locker.svg'
      if (locker.activeDelivery && locker.activeDelivery.deliveryType == 2) return '/lockers/return-box.svg'
      if (locker.doorStatus === 1) return '/lockers/locker_alarm.svg'
      if (locker.doorStatus === 3) return '/lockers/locker_alarm.svg'
      if (locker.doorStatus === 11) return '/lockers/locker_alarm.svg'
      if (locker.accessibility) return '/lockers/locker_accessible_v3.svg'
      if (locker.status === 2) {
        if (locker.locker_users && locker.locker_users[0]) {
          return '/lockers/locker_closed_v2.svg'
        } else if (locker.activeDelivery && locker.activeDelivery.deliveryType === 1) {
          return '/lockers/package.svg'
        } else {
          return '/lockers/locker_closed_v2.svg'
        }
      }
      if (locker.status === 4) {
        if (locker.activeDelivery && locker.activeDelivery.deliveryType === 1) {
          return '/lockers/package.svg'
        } else if (locker.activeDelivery && locker.activeDelivery.deliveryType === 3) {
          return '/lockers/user.svg'
        } else {
          return '/lockers/package.svg'
        }
      }
      if (locker.doorStatus === 255) return '/lockers/locker_alarm.svg'
      if (locker.doorStatus === 2) return '/lockers/locker_closed_v2.svg'
      if (locker.doorStatus === 0) return '/lockers/locker_open_v2.svg'
    } else if (locker.enabled === 3) {
      return '/lockers/locker_clean.svg'
    }
  }

  getSizeIcon = (locker) => {
    if (locker.size === 'S') return '/lockers/locker_helmet.svg'
    if (locker.size === 'M') return '/lockers/locker_scooter.svg'
    if (locker.size === 'L') return '/lockers/locker_bicicle.svg'
  }

  render() {
    const { locker, clickCallback, t, lockerSafeTime } = this.props

    return (
      <div className={`locker ${this.getLockerClass(locker)}`} onClick={() => clickCallback(locker)} style={{ boxShadow: this.props.selected ? '0px 4px 10px 0px rgba(0, 0, 0, 0.4)' : '', cursor: this.props.noPointer ? 'auto' : 'pointer' }}>
        <span className="number">{locker.doorNumber}</span>
        <span className="size">{locker.size}</span>
        <ReactSVG src={this.getLockerIcon(locker)} className="locker-icon" />
        {locker.doorStatus === 0 && (locker.status === 1 || locker.status === 2 || locker.status === 4 || locker.status === 7) &&
          <div className="open-occupied-sign"></div>
        }
        {locker.status === 4 && locker.doorStatus !== 0 && moment(locker.activeDelivery.storedAt).diff(Date.now(), 'days') !== 0 &&
          <p className={`locker-${getTimeAgoClass(locker.activeDelivery.storedAt) + " order-deposited-since"}`}>{locker.activeDelivery.storedAt && moment(locker.activeDelivery.storedAt).diff(Date.now(), 'days').toString().replace(/-/g, '')} {t("ordersList.days")}{moment(locker.activeDelivery.storedAt).diff(Date.now(), 'days') !== -1 && <>s</>}</p>
        }
        {locker.status === 4 && locker.doorStatus !== 0 && moment(locker.activeDelivery.storedAt).diff(Date.now(), 'days') === 0 &&
          <p className="locker-time-chip green order-deposited-since">{locker.activeDelivery.storedAt && moment(locker.activeDelivery.storedAt).diff(Date.now(), 'hours').toString().replace(/-/g, '')} {t("ordersList.hours")}{moment(locker.activeDelivery.storedAt).diff(Date.now(), 'hours') !== -1 && <>s</>}</p>
        }
        {(locker.status === 8 && locker.doorStatus !== 0 && locker.enabled && locker.releaseAt) &&
          <p className="position-absolute text-white w-100 text-center bg-warning" style={{ bottom: -15, fontSize: "0.65em", borderTop: "1px solid white" }}>
            {calculateRemainingTime(locker.releaseAt, lockerSafeTime)}
          </p>
        }
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    lockerSafeTime: state.locker_safe_time.list.time
  }
}

export default connect(mapStateToProps)(withTranslation('common')(Locker))
