const lockerSafeTimeReducer = (state = { list : [], selected: null }, action) => {
  switch (action.type) {
    case "GET_LOCKER_SAFE_TIME_LIST_SUCCESS":
      return { ...state, list: action.payload }
    case "GET_LOCKER_SAFE_TIME_DETAILS_SUCCESS":
      return { ...state, selected: action.payload }
    default:
      return state
  }
}

export default lockerSafeTimeReducer
