const invoiceReducer = (state = { list: [], selected: null, download: false }, action) => {
  switch (action.type) {
    case "GET_INVOICE_LIST_SUCCESS":
      return { ...state, list: action.payload }
    case "GET_INVOICE_DETAILS_SUCCESS":
      return { ...state, selected: action.payload }
    case "DOWNLOAD_INVOICE_SUCCESS":
      return { ...state, download: action.payload }
    default:
      return state
  }
}

export default invoiceReducer
