import moment from 'moment-timezone';

export const calcIvaValue = (ivaTax, price) => {

  //Transform per example 16, in 1.16 to calc pricw without IVA tax---------------------------------------
  let ivaToCalc = (ivaTax / 100) + 1;

  let priceWithoutIva = (price / ivaToCalc);

  let increaseAmountOfIva = (priceWithoutIva.toFixed(2) * parseInt(ivaTax) / 100);

  let result = (parseFloat(priceWithoutIva.toFixed(2)) + parseFloat(increaseAmountOfIva.toFixed(2))).toFixed(2);

  if (priceWithoutIva) {

    return increaseAmountOfIva.toFixed(2);

  } else {

    return 0;

  }

}

export const calcPriceValue = (ivaTax, price) => {

  //Transform per example 16, in 1.16 to calc pricw without IVA tax---------------------------------------
  let ivaToCalc = (ivaTax / 100) + 1;

  let priceWithoutIva = (price / ivaToCalc);

  return priceWithoutIva.toFixed(2);
}

export const calcMinutes = (hours) => {

  let minutes = hours * 60;

  return minutes;
}

export const calcHours = (minutes) => {

  let hours = minutes / 60;

  return hours;
}

export const formatPrice = (price) => {

  let formatedPrice = parseFloat(price).toFixed(2).toString().replace(".", ",")

  return formatedPrice;
}

export const formatTime = (minutes) => {

  let hours = Math.floor(minutes / 60);
  let remainingMinutes = minutes % 60;

  let formattedTime = '';

  if (hours > 0) {
    formattedTime += hours + 'h';
  }

  if (remainingMinutes > 0) {
    formattedTime += ' ' + remainingMinutes + 'min';
  }

  return formattedTime;
}

export const calculateTimeDifference = (time, endTime = false) => {
  if (!time) return '';

  let now = moment();

  if (endTime) {
    now = moment(endTime);
  }

  const requestTime = moment(time);

  const duration = moment.duration(now.diff(requestTime));
  // Subtract one hour from the duration

  const hours = Math.floor(duration.asHours());
  const minutes = Math.floor(duration.asMinutes() % 60);

  if (hours <= -2) {
    const formattedMinutes = String(minutes).padStart(2, '0');
    return `${hours + 1}h ${formattedMinutes}min`;
  } else if (hours <= 0) {
    return `${minutes}min`;
  } else {
    const formattedMinutes = String(minutes).padStart(2, '0');
    return `${hours}h ${formattedMinutes}min`;
  }
};

export const calculateRemainingTime = (releaseTime, duration) => {
  if (!releaseTime || !duration) return '';

  // Calculate expiry time by adding duration to release time
  const expiryMoment = moment(releaseTime).add(duration, 'minutes');

  // Current time
  const now = moment();

  // Calculate remaining time in milliseconds
  const remainingMilliseconds = expiryMoment.diff(now);

  if (remainingMilliseconds <= 0) {
    return 'Expired';
  }

  // Convert remaining time to minutes and seconds
  const remainingMinutes = Math.floor(remainingMilliseconds / (1000 * 60));

  return `${remainingMinutes} min`;
};